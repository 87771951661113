<template>
  <section class="m-merchant-type m-scroll">
    <!-- 头部导航 -->
    <m-header title="Partner"></m-header>

        <van-radio-group v-model="radio" class="type-radio">
        <van-cell-group>
          <van-cell title="Business partner" clickable @click="radio = '1'" class="type-cell">
            <template #right-icon>
              <van-radio name="1" />
            </template>
          </van-cell>
          <van-cell title="Personal partner" clickable @click="radio = '2'" class="type-cell">
            <template #right-icon>
              <van-radio name="2" />
            </template>
          </van-cell>
        </van-cell-group>
      </van-radio-group>
      



      <div class="footer">
        <van-button @click="onNext" round block type="info"  color="linear-gradient(315deg,rgba(240,60,24,1) 0%,rgba(240,100,72,1) 100%)">Next</van-button>
      </div>
  </section>
</template>

<script>
import MHeader  from '@/components/en/m-header.vue'
export default {
  name:'Index',
  components:{ MHeader },
  data(){
    return {
      radio: '',
      status: 0 ,
      apply_type: 0,
      disabled: true
    }
  },

  methods:{

    // 提交
    onNext(){
      if(this.radio == ""){
        return false
      }else {
        this.$router.push({path: `/en/partner/application/${this.radio}`})
      }
    },
  },
}
</script>


<style lang="less" scoped>
.m-merchant-type {
  width: 100%;
  height: 100%;
  background-color: #F5F9FF;
  padding-top: 44px;
  overflow-x: hidden;
  overflow-y: auto;
  .type-radio {
    margin-top: 12px;
  }
  .type-cell {
    height: 70px;
    /deep/ .van-cell__title {
      display: flex;
      align-items: center;;
    }
  }
  .footer {
    margin: 100px 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 30px;
  }
}
 
</style>